import React from "react";
import { Link, graphql } from "gatsby";

import Seo from "../components/Seo";
import styled from "styled-components";

const Wrap = styled.div`
  background-color: #eac444;
  color: white;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  @media only screen and (max-width: 60em) {
    display: block;
    padding: 0 0;
  }
`;

const NotFoundPage = ({ data, location }) => {
  return (
    <>
      <Wrap>
        <Seo title="404: Not Found" />
        <Link
          to="/"
          style={{ textDecoration: "none", color: "white", fontSize: "112px" }}
        >
          Sutra.hr
        </Link>
        <h1>404: Stranica nije pronađena</h1>
        <p>Provjerite vaš link</p>{" "}
      </Wrap>
    </>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
